import FuseUtils from '@fuse/utils/FuseUtils';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import jwtServiceConfig from './jwtServiceConfig';
import { ME } from '../../../assets/user';
import request from 'src/app/assets/request';

/* eslint-disable camelcase */

class JwtService extends FuseUtils.EventEmitter {
  init() {
    this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        return new Promise((resolve, reject) => {
          if (err.response && err.response.status && err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
            // if you ever get an unauthorized response, logout the user
            this.emit('onAutoLogout', 'Invalid access_token');
            this.setSession(null);
          }
          throw err;
        });
      }
    );
  };

  handleAuthentication = () => {
    const access_token = this.getAccessToken();

    if (!access_token) {
      this.emit('onNoAccessToken');

      return;
    }

    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token);
      this.emit('onAutoLogin', true);
    } else {
      this.setSession(null);
      this.emit('onAutoLogout', 'access_token expired');
    }
  };

  createUser = (data) => {
    return new Promise((resolve, reject) => {
      axios.post(jwtServiceConfig.signUp, data).then((response) => {
        if (response.data.user) {
          this.setSession(response.data.access_token);
          resolve(response.data.user);
          this.emit('onLogin', response.data.user);
        } else {
          reject(response.data.error);
        }
      });
    });
  };

  signInWithEmailAndPassword = () => {
    return new Promise((resolve, reject) => {
      const access_token = this.getAccessToken();
      if(access_token) {
        this.setSession(access_token);
      } else {
        this.logout();
        reject(new Error('Failed to login with token.'));
      }
      request(ME, undefined, `Bearer ${access_token}`)
      .then(res => {
        return res.json();
      })
      .then(response => {
        if(response.errors) {
          throw new Error('Login failed');
        }
        if (response.data.getMe) {
          this.setSession(access_token);
          const user = {
            displayName: response.data.getMe.name,
            email: response.data.getMe.email,
            role: 'user',
          }
          resolve(user);
          this.emit('onLogin', user);
        } else {
          this.logout();
          reject(new Error('Failed to login with token.'));
        }
      })
      .catch(err => {
        reject(new Error(err.message));
      });
    });
  };

  signInWithToken = () => {
    return new Promise((resolve, reject) => {
      const access_token = this.getAccessToken();
      if(access_token) {
        this.setSession(access_token);
      } else {
        this.logout();
        reject(new Error('Failed to login with token.'));
      }
      request(ME, undefined, `Bearer ${access_token}`)
      .then(res => {
          return res.json();
      })
      .then(response => {
        if(response.errors) {
          throw new Error('Login failed');
        }
        if (response.data.getMe) {
          this.setSession(access_token);
          const user = {
            displayName: response.data.getMe.name,
            email: response.data.getMe.email,
            role: 'user',
          }
          resolve(user);
        } else {
          this.logout();
          reject(new Error('Failed to login with token.'));
        }
      })
      .catch(err => {
        reject(new Error(err.message));
      });
    });
  };

  updateUserData = (user) => {
    return axios.post(jwtServiceConfig.updateUser, {
      user,
    });
  };

  setSession = (access_token) => {
    if (access_token) {
      localStorage.setItem('access_token', access_token);
    } else {
      localStorage.removeItem('access_token');
    }
  };

  setTokenSession = (access_token, refresh_token) => {
    if (access_token) {
      localStorage.setItem('access_token', access_token);
      localStorage.setItem('refresh_token', refresh_token);
    } else {
      localStorage.removeItem('access_token');
    }
  };

  logout = () => {
    this.setSession(null);
    this.emit('onLogout', 'Logged out');
  };

  registered = () => {
    this.setSession(null);
    this.emit('onRegistered');
  };

  isAuthTokenValid = (access_token) => {
    if (!access_token) {
      return false;
    }
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn('access token expired');
      return false;
    }

    return true;
  };

  getAccessToken = () => {
    return window.localStorage.getItem('access_token');
  };
}

const instance = new JwtService();

export default instance;
