import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';

export const ErrorComponent = ({ error }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (error) {
      setVisible(true);
      const hideErrorTimeout = setTimeout(() => {
        setVisible(false);
      }, 5000); 

      return () => {
        clearTimeout(hideErrorTimeout);
      };
    }
  }, [error]);

  return (
    visible &&
    error && (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '15px', marginBottom: '0' }}>
        <Typography variant="body2" color="error">
          {error}
        </Typography>
      </div>
    )
  );
};
