import CONSTANTS from "src/constants";
import { print } from 'graphql';

function request(gql, data, authentication, content) {
    if(authentication && data && !content) {
        return fetch(CONSTANTS.URL, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'authentication': authentication,
            },
            body: JSON.stringify({ query: print(gql), variables: data })
          })
    } else if (authentication && !data && !content){
        return fetch(CONSTANTS.URL, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'authentication': authentication
            },
            body: JSON.stringify({ query: print(gql) })
          })
    } else if (!authentication && !data && !content) {
        return fetch(CONSTANTS.URL, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ query: print(gql) })
          })
    } else if (content) {
      return fetch(CONSTANTS.URL, {
        method: 'POST',
        headers: {
          'apollo-require-preflight': 'true',
        },
        body: JSON.stringify({ query: print(gql), variables: data })
      })
    } else {
        return fetch(CONSTANTS.URL, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ query: print(gql), variables: data })
          })
    }
}

export default request;