import { gql } from '@apollo/client';

const CREATE_USER = gql`
  mutation register($name: String!, $email: String!, $password: String!, $confirmPassword: String!, $type: USER_TYPE!) {
    register(userInput: { name: $name, email: $email, password: $password, confirmPassword: $confirmPassword, type: $type })
  }
`;

const ACTIVATE_USER = gql`
  mutation activateUser($email: String!, $code: String!) {
    activateUser(activateUserInput: { email: $email, code: $code })
  }
`;

const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(loginInput: { email: $email, password: $password }) {
      authToken
      expiresAuth
      refreshToken
      expiresRefresh
    }
  }
`;

const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!, $newPassword: String!, $code: String!) {
    forgotPassword(forgotPasswordInput: { email: $email, newPassword: $newPassword, code: $code }) {
      id
    }
  }
`;

const SENT_CODE = gql`
  mutation sendVerificationCode($email: String!) {
    sendVerificationCode(emailInput: { email: $email})
  }
`;

const LOGIN_GOOGLE = gql`
  mutation loginByGoogle($accessToken: String!) {
    loginByGoogle(accessToken: $accessToken) {
      authToken
      expiresAuth
      refreshToken
      expiresRefresh
    }
  }
`;

const USER_SUBSCRIBE = gql`
  mutation userSubscribe($planId: String!) {
    userSubscribe(planId: $planId) {
      id
    }
  }
`;

const CHANGE_AUTO_ACTIVATION = gql`
  mutation changeAutoActivation($autoActivation: Boolean!, $subscriptionId: String!,) {
    changeAutoActivation(autoActivation: $autoActivation, subscriptionId: $subscriptionId) {
      autoActivation
    }
  }
`;

const ADD_CARD = gql`
  mutation addPaymentCard($tokenId: String!) {
    addPaymentCard(tokenId: $tokenId) {
      paymentId
      cardNumber
      expirationMonth
      expirationYear
      type
      isDefault
    }
  }
`;

const DELETE_CARD = gql`
  mutation deleteCard($paymentId: String!) {
    deleteCard(paymentId: $paymentId)
  }
`;

const UPDATE_DEFAULT_CARD = gql`
  mutation updateDefaultCard($paymentId: String!) {
    updateDefaultCard(paymentId: $paymentId)
  }
`;

const CREATE_STRIPE_ACCOUNT = gql`
  mutation createStripeAccount{
    createStripeAccount{
      id
    }
  }
`;

const CREATE_ATTACHMENT = gql`
    mutation createAttachment($contentType: String!, $name: String!, $videoId: String!) {
      createAttachment(attachmentInput: { contentType: $contentType, name: $name, videoId: $videoId })
  }
`;

const FINISH_ATTACHMENT = gql`
    mutation finishAttachment($videoId: String!) {
      finishAttachment(videoId: $videoId )
  }
`;

const DELETE_ATTACHMENT = gql`
    mutation deleteAttachment($videoId: String!) {
      deleteAttachment(videoId: $videoId )
  }
`;

const USE_API = gql`
    mutation useApiKey {
      useApiKey
  }
`;

const PROMO = gql`
    mutation usePromoCode($promoCode: String!) {
      usePromoCode(promoCode: $promoCode)
  }
`;

const ME = gql`
  query getMe {
    getMe {
      id,
      name,
      email,
      type,
      role,
      code,
      isActive,
      stripeId
    }
  }
`;

const MY_PLANS = gql`
  query getMySubscriptionPlans {
    getMySubscriptionPlans {
      callCount,
      price,
      key,
      currency,
      subscriptionId,
      duration,
      startDate,
      endDate,
      updateCallCount,
      autoActivation,
      planName,
      key
    }
  }
`;

const PLANS = gql`
  query getProducts {
    getProducts {
      id
      name
      productId
      isDeleted
      plans {
        planId
        duration
        price
        currency
        callCount
      }
    }
  }
`;

const PAYMENTS = gql`
  query getPaymentCard {
    getPaymentCard {
      paymentId
      cardNumber
      expirationMonth
      expirationYear
      type
      isDefault
    }
  }
`;

const UPLOAD_LINK = gql`
  query getUploadLink($attachmentId: String!) {
    getUploadLink(attachmentId: $attachmentId)
  }
`;

const PROCESS_VIDEO = gql`
  query getProcessVideo {
    getProcessVideo
  }
`;

const DOWNLOAD_LINK = gql`
  query getDownloadLink($attachmentId: String!) {
    getDownloadLink(attachmentId: $attachmentId)
  }
`;

const MY_DOWNLOAD_LINKS = gql`
  query getMyDownloadLinks {
    getMyDownloadLinks {
      link
      name
      date
      contentType
    }
  }
`;

export { CREATE_USER, ACTIVATE_USER, LOGIN, FORGOT_PASSWORD, SENT_CODE, LOGIN_GOOGLE, 
  ME, PLANS, MY_PLANS, USER_SUBSCRIBE, CHANGE_AUTO_ACTIVATION, PAYMENTS, ADD_CARD, DELETE_CARD, 
  UPDATE_DEFAULT_CARD, CREATE_STRIPE_ACCOUNT, CREATE_ATTACHMENT, UPLOAD_LINK, DOWNLOAD_LINK, 
  MY_DOWNLOAD_LINKS, DELETE_ATTACHMENT, FINISH_ATTACHMENT, PROCESS_VIDEO, USE_API, PROMO };