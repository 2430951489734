import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

export const ErrorSnackbar = ({ open, onClose, message }) => {
    return (
      <Snackbar open={open} autoHideDuration={5000} onClose={onClose}>
        <MuiAlert onClose={onClose} severity="error" elevation={8} variant="filled">
        {message}
        </MuiAlert>
      </Snackbar>
    );
  };
  